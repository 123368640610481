import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Card, Divider, Paper, Fade, Slide } from '@mui/material';
import ImageViewer from "react-simple-image-viewer";
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios'
// components
import './beranda.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useEffect } from 'react';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import { useNavigate } from 'react-router-dom';
import YouTubeVideo from '../components/youtube/YoutubeVideo'
// import { AppKudapanTerbaru } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  // const theme = useTheme();
  const API_URL = process.env.REACT_APP_API
  const BACKEND_API = process.env.REACT_APP_BE
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    // Navigate to a specific page or perform an action when the card is clicked
    // window.location.href = `${id}`;
    navigate(`${id}`)
  };
  // const [images, setImages] = useState([])
  // eslint-disable-next-line
  let images = []
  
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = async (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [beranda, setBeranda] = useState([])
  const getBeranda = async () => {
    try {
      await axios.get(`${API_URL}beranda/allBeranda`)
      .then(({data}) => {
        setBeranda(data?.data)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setBeranda([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }

  const [slidePic, setSlidePic] = useState('')
  const [slider, setSlider] = useState([])

  const [supportPic, setSupportPic] = useState('')

  slider?.map((src) => 
      // console.log(`${BACKEND_API}${src.link}`)
      images.push(`${BACKEND_API}${src.link}`)
  )

  const getSlider = async () => {
    try {
      await axios.get(`${API_URL}slider/allSlider`)
      .then(({data}) => {
        setSlider(data?.data)
        setSlidePic(<Carousel 
          autoPlay 
          infiniteLoop
          dynamicHeight
          fullWidth
          autoFocus
          showThumbs={0}
          showStatus={0}
          >
          {
            data?.data?.map((src, index) => (
          
              <div key={index} onClick={() => openImageViewer(index)} role='presentation'>
                  <img src={`${BACKEND_API}${src.link}`} style={{ 
                    borderRadius: '10px', 
                    width: '100%',  // Optional: Adjust the width as needed
                    height: 'auto', // Optional: Adjust the height as needed
                    objectFit: 'cover'  // Optional: Ensure images cover the space well
                  }} alt='img' className='sliderHeight'/>
              </div>
            
        
            ))
          }
        </Carousel>)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setSlider([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }

  const getSupport = async () => {
    try {
      await axios.get(`${API_URL}support/allSupport`)
      .then(({data}) => {
        setSupportPic(<Carousel 
          autoPlay 
          infiniteLoop
          // dynamicHeight
          fullWidth
          // autoFocus
          showThumbs={0}
          showStatus={0}
          // centerMode={1}
          >
          {
            data?.data?.map((src, index) => (
          
              <div key={index} >
                
              <div onClick={() => {window.open(src.linkSupport)}} style={{cursor: 'pointer'}} role='presentation'>
                  <img src={`${BACKEND_API}${src.link}`} alt='img' style={{ width: '100px' }}/>
              </div>
              <div style={{marginTop: "20px"}}>
                <Typography variant='subtitle1'>{src.nama}</Typography>
              </div>

              <div style={{marginTop: "20px"}}>
                <Typography variant='subtitle1'>&nbsp;</Typography>
              </div>
              
              </div>
        
            ))
          }
        </Carousel>)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setSlider([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }

  const [beritaTerbaru, setBeritaTerbaru] = useState([])

  const getBeritaTerbaru = async () => {
    try {
      await axios.get(`${API_URL}berita/beritaTerbaru`)
      .then(({data}) => {
        setBeritaTerbaru(data?.data)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setBeritaTerbaru([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }

  const [kudapanTerbaru, setKudapanTerbaru] = useState([])

  const getKudapanTerbaru = async () => {
    try {
      await axios.get(`${API_URL}join/allKudapan`)
      .then(({data}) => {
        setKudapanTerbaru(data?.data)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setKudapanTerbaru([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getBeranda();
    getSlider();
    getSupport();
    getBeritaTerbaru();
    getKudapanTerbaru();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
   
    <div style={{backgroundColor: "white"}} >
      <Helmet>
      <title> {localStorage.getItem("judul") !== null ? localStorage.getItem("judul") : ''} | Beranda </title>
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content="Gastronomi Kuliner Bandung Barat" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <Container maxWidth="lg">
        <Fade in timeout={4000}>
        <Typography className='text1' style={{color: '#785412'}}>
          {beranda[0]?.judul}
        </Typography>
        </Fade>
        <Grid container spacing={2} >
          {/* <Grid item xs={8} sm={8} md={8} >
          <Paper className='paper1' square style={beranda[0]?.image1 !== undefined ? {backgroundImage: `url(${BACKEND_API}${beranda[0]?.image1})`, 
          backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "350px", marginTop:"15px"} : {}}/>
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
          {/* <Card> */}
            {/* <center><Typography variant='h5' style={{marginBottom:"15px"}}>Gallery Foto</Typography></center> */}
      
            <Fade in timeout={4000}>
            <div className="carousel-wrapper">
              {slidePic}
            </div>
            </Fade>
           
     
              {isViewerOpen && (
                <>
                <ImageViewer
                  src={images} 
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)"
                  }}
                  closeOnClickOutside={1}
                />
                
                </>
                
              )}
    
            {/* </Card> */}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <Card> */}

            
            <div style={{marginTop:"10px", marginBottom:"10px"}}>
            <Fade in timeout={4000}><Typography variant='h5'>{beranda[0]?.judul}</Typography></Fade>
            <Fade in timeout={4000}><Typography variant='subtitle1'>{beranda[0]?.sub_judul}</Typography></Fade>
            <Fade in timeout={4000}><Divider variant="fullWidth" style={{ margin: "10px 0"}}/></Fade>
            {/* eslint-disable-next-line  */}
            <Fade in timeout={4000}><Typography component={'div'} style={{textAlign: 'justify'}} variant='body2'><div dangerouslySetInnerHTML={{__html: beranda[0]?.content}} />
            </Typography></Fade>
            </div>
            {/* </Card> */}
          </Grid>

          <Slide direction='up' in timeout={2000}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <Card
              style={{
                padding: 32,
                backgroundImage: "url('https://api.gastronomi.fun/drive/Desain tanpa judul (5)_1734162520502.png')",
                backgroundSize: "cover", 
                backgroundRepeat: "no-repeat", 
                backgroundPosition: "center",
                color: "#785412"

              }}
            >
              <FoodBankOutlinedIcon style={{ fontSize: 40 }} />
              <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
              <Typography style={{ textAlign: 'justify', marginTop: '15px' }}>
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: beranda[0]?.rightContent }} />
              </Typography>
            </Card>
          </Grid>

          </Slide>

          <Slide direction='up' in timeout={2000}>
          <Grid item xs={12} md={12} lg={12} sx={{padding:2, marginBottom:"20px", marginTop:"20px"}}>
            <Paper>
            <Grid container spacing={2}  >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Paper>
                <h1>Tentang</h1>
                <h1>{beranda[0]?.kabupatenkota}</h1>
                <div style={{marginTop: 16, marginBottom: 16}}>
                  {/* eslint-disable-next-line  */}
              <div style={{marginLeft: 15}} dangerouslySetInnerHTML={{__html: beranda[0]?.leftAbout}} />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Paper sx={{textAlign:"center"}}>
              <img src={`${BACKEND_API}${beranda[0]?.image2}`} alt='map'/>
              </Paper>
              <h5 style={{textAlign:"center", marginTop: 16}}>Peta {beranda[0]?.kabupatenkota}</h5>
            </Grid>
            </Grid>
            </Paper>  
          </Grid>
          </Slide>
          {/* <Grid container spacing={2}> */}
          {/* Outer Grid */}

          <Slide direction='up' in timeout={7000}>
          <Grid item xs={12} md={12} lg={12} sx={{textAlign:"center", marginBottom: '5px'}}>
            <Card style={{
                padding: 32,
                backgroundImage: "url('https://api.gastronomi.fun/drive/Desain tanpa judul (5)_1734162520502.png')",
                backgroundSize: "cover", 
                backgroundRepeat: "no-repeat", 
                backgroundPosition: "center",
                color: "#785412"

              }}>
              {/* Inner Grid */}
              <Grid container spacing={2} >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Paper style={{ textAlign: 'center', padding: 8, backgroundColor: 'transparent', color: "#785412" }}>
                    <Typography style={{fontSize:"50px", fontWeight:"bold"}}>{beranda[0]?.rumahMakan?.toLocaleString('id-ID')}</Typography>
                    <Typography >Rumah Makan</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Paper style={{ textAlign: 'center', padding: 8, backgroundColor: 'transparent', color: "#785412" }}>
                    <Typography style={{fontSize:"50px", fontWeight:"bold"}}>{beranda[0]?.umkm?.toLocaleString('id-ID')}</Typography>
                    <Typography>UMKM Kuliner</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Paper style={{ textAlign: 'center', padding: 8, backgroundColor: 'transparent', color: "#785412" }}>
                    <Typography style={{fontSize:"50px", fontWeight:"bold"}}>{beranda[0]?.hotel?.toLocaleString('id-ID')}</Typography>
                    <Typography>Hotel</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          </Slide>

          <Slide direction='up' in timeout={8000}>
          <Grid item xs={12} md={12} lg={12}>
            <YouTubeVideo videoId={beranda[0]?.email}/>
          </Grid>
          </Slide>

          <Slide direction='up' in timeout={8000}>
          <Grid item xs={12} md={12} lg={12}>
          <Card style={{ padding: 20 }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Makanan Khas</h1>
            <Grid container spacing={2} style={{ textAlign: 'center' }}>
              {kudapanTerbaru?.slice(0, 3)?.map((data, i) => (
                <Grid item xs={12} sm={4} md={4} lg={4} key={i}>
                  <Card
                    className="kudapan"
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      // height: '500px', // Specify a fixed height for the card
                      overflow: 'hidden',
                    }}
                    onClick={() => handleCardClick(`/detail/${data?.id}`)}
                  >
                    <img
                      src={`${BACKEND_API}${data?.image1}`}
                      alt={data?.nama_makanan}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Paper style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
                        <div style={{ display: 'block' }}>
                          <RestaurantOutlinedIcon style={{ color: 'white', fontSize: 40, fontWeight: 'bold' }} />
                        </div>
                        <h2 style={{ color: 'white', padding: 15 }}>{data?.nama_makanan}</h2>
                      </Paper>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Typography
              onClick={() => {
                window.location.href = "/makanan-khas";
              }}
              style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px', cursor: 'pointer' }}
            >
              Lihat Selengkapnya
            </Typography>
          </Card>
        </Grid>
          </Slide>
          

          <Slide direction='up' in timeout={9000}>
          <Grid item xs={12} md={12} lg={12}>
          <Card style={{marginBottom:"15px", padding:"20px", borderRadius: '15px', marginTop:10}}>
          <h1 style={{textAlign: 'center', marginBottom: '20px'}}>Berita Terbaru</h1>

              <Grid container spacing={2} style={{textAlign:'center'}}>
              {
                beritaTerbaru?.map((data, i) => (
                  
                  <Grid item xs={12} md={12} lg={4} key={i}>
                    {/* <Card className='berita' style={{
                      backgroundImage: `url(${BACKEND_API}${data?.image1})`,
                      backgroundSize: 'cover',
                      // height: '200px',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleCardClick(`/berita/${data?.id}`)}
                    >
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(1px)',
                      }}>
                        <h2 style={{ color: 'white', padding:15 }}>{data?.judul?.slice(0,50)}...</h2>
                      </div>
                    </Card> */}

                    <Card className='berita' style={{
                      position: 'relative',
                      cursor: 'pointer',
                      height: '200px',
                      overflow: 'hidden' // Untuk memastikan gambar tidak keluar dari batas Card
                    }}
                    onClick={() => handleCardClick(`/berita/${data?.id}`)}
                    >
                      <img 
                        src={`${BACKEND_API}${data?.image1}`} 
                        alt="background" 
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover', // Mengisi seluruh elemen Card dengan gambar
                          zIndex: -1 // Menjadikan gambar berada di belakang konten Card
                        }} 
                      />
                        <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(1px)',
                      }}>
                        <h2 style={{ color: 'white', padding:15 }}>{data?.judul?.slice(0,50)}...</h2>
                      </div>
                      </Card>

                  </Grid>

                ))
              }
             </Grid>
              
              <div style={{marginTop:"20px", textAlign:"center"}}>
              {/* <a style={{color:"black", fontWeight:'bold', textAlign:"center"}} href='/berita'>Lihat semua berita</a> */}
            <Typography onClick={() => {window.location.href='/berita'}} style={{textAlign: 'center', marginBottom: '20px', marginTop: '20px', cursor:'pointer'}}>Lihat Selengkapnya</Typography>

              </div>
          </Card>
            
          </Grid>
          </Slide>
         
          <Slide direction='up' in timeout={8000}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{padding:20}}>
              <h1 style={{textAlign: 'center'}}>Dukungan</h1>
              {/* <div className='dukungan'>
                <Paper className='paper-mobile' style={{maxWidth: 200}}>
                <div style={{textAlign: 'center', alignItems:'center', justifyContent: 'center', display:'flex'}}>
                  <img height={100} src='https://upload.wikimedia.org/wikipedia/id/0/09/Logo_Almamater_UPI.svg' alt='dukungan'/>
                </div>
                <div style={{marginTop:'10px'}}>
                  <Typography style={{textAlign:'center'}}>Universitas Pendidikan Indonesia</Typography>
                </div>
                </Paper>


                
              </div> */}
            <Container maxWidth="xs" style={{marginTop:'40px'}}>

            <div className="carousel-wrapper">
              {supportPic}
            </div>
            </Container>

            </Paper>
          </Grid>
          </Slide>
         


        
        </Grid>
      </Container>
    </div>
  );
}
