import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box, Grid } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import { useEffect, useState } from "react";

import axios from 'axios'


export default function Footer() {
  const API_URL = process.env.REACT_APP_API
  const BACKEND_API = process.env.REACT_APP_BE

  const [beranda, setBeranda] = useState([])
  const getBeranda = async () => {
    try {
      await axios.get(`${API_URL}beranda/allBeranda`)
      .then(({data}) => {
        setBeranda(data?.data)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setBeranda([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBeranda();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        
        p: 3,
      }}
      component="footer"
    >
      <Container maxWidth="sm">
      <Grid container spacing={1} sx={{textAlign:"center"}}>
    
      <Grid item xs={12} sm={12} md={12} lg={12}>

      <Grid container spacing={1} sx={{textAlign:"center"}}>
        <Grid item xs={12} sm={12} md={6} lg={6}>

          <center><img src={`${BACKEND_API}${beranda[0]?.logo}`}  alt={beranda}/></center>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography style={{marginTop:"25px"}}  variant="body2" color="text.secondary" align="center">
              <a style={{color: 'black'}} href={beranda[0]?.instagram} target="_blank" rel="noreferrer"><InstagramIcon/></a>
          </Typography>
          <Typography style={{marginTop:"5px"}} variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href={`${window.location.protocol}`} style={{textDecoration:'none'}}>
              {localStorage.getItem('judul') !== null ? localStorage.getItem('judul') : ''}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
          
        </Grid>
      </Grid>
      </Grid>
          
          
        </Grid>
      </Container>
    </Box>
  );
}